<template>
  <notice-list :category="11" detailPath="warningInfoDetail"/>
</template>
<script>
import NoticeList from '@/components/NoticeList.vue'

export default {
  name: 'banner',
  components: {
    NoticeList
  },
  data() {
    return {}
  },
  created() {},
  methods: {}

}
</script>

<style>
</style>