<template>
  <!-- 我提出的 事件上报和民事调解 -->
  <div class="container">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
      <div class="articles">
        <div class="item" v-for="(item, index) in list" :key="index" @click="goDetail(item.id)">
          <!-- <img :src="item.cover" alt=""> -->
          <van-image fit="cover" :src="item.cover" />
          <div class="right">
            <div class="title van-ellipsis">{{ item.title }}</div>
            <div class="desc">
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="diyCell" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <div class="top">
          <div class="title van-ellipsis">{{ item.title }}</div>
        </div>
        <p class="van-multi-ellipsis--l3">{{ item.description }}</p>
        <div class="time">
          <span>{{ item.createTime }}</span>
          <van-icon name="arrow" />
        </div>
      </div> -->
    </van-list>
  </div>
</template>
<script>
import { getNoticeList } from '@/api/eventApi'
export default {
  props: {
    category: {
      type: [Number],
      required: false,
      default: 0,
    },
    type: {
      type: [Number],
      required: false,
      default: 1,
    },
    detailPath: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
    }
  },

  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    goDetail(id) {
      this.$router.push({
        path: '/' + this.detailPath + '?id=' + id,
      })
    },
    getList() {
      if (this.currentList !== 0) {
        getNoticeList({
          // openid: this.$store.getters.openId,
          category: this.category,
          type: this.category==14?'':this.type,
          current: this.page,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.currentList = res.data.records ? res.data.records.length : 0
            this.list = this.list.concat(res.data.records)
            this.page++
          }
        })
      } else {
        this.finished = true
      }
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  padding: 20px;
}

.van-cell__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ml {
  margin-left: 5px;
}
.articles {
  width: 100%;
  margin-top: 12px;
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;

    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 14px 12px;
    border-radius: 8px;
  }
  .van-image {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .right {
    flex: 1;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      width: 200px;
      font-size: 12px;
      font-weight: 700;
    }
    .desc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
