import axios from '@/utils/axios';
// const baseURL2 = '/communityApi'
const baseURL = '/api'

// 提出
export const ask = (data) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/submit',
    method: 'post',
    data
  });
};

// 在线指导专家回答问题
export const answer = (data) => {
  return axios({
    baseURL,
    url: '/village/agriculture/answer/submit',
    method: 'post',
    data
  });
};

// 事件上报
export const uploadEvent = (data) => {
  return axios({
    baseURL,
    // url: '/blade-system/notice/submit',
    url: '/blade-system/notice/public/submit',
    method: 'post',
    data
  });
};

// 警情上报
export const subWarning = (data) => {
  return axios({
    baseURL,
    // url: '/manage/uploadEvent/submit',
    url: '/manage/uploadEvent/public/submit',
    method: 'post',
    data
  });
};

// 获取问答详情
export const getAskDetail = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/detail',
    method: 'get',
    params
  });
};

// 获取详情(事件、调解)
export const getDetail = (params) => {
  return axios({
    baseURL,
    url: '/manage/uploadEvent/detail',
    method: 'get',
    params
  });
};

// 满意度回访（评价在线指导）
export const setStar = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/evaluate',
    method: 'post',
    params
  });
};

// 满意度回访（事件、调解评价）
export const setStar2 = (params) => {
  return axios({
    baseURL,
    url: '/manage/uploadEvent/evaluate',
    method: 'post',
    params
  });
};

// 意见反馈类型
export const opinionType = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/dict/dictionary?code=opinion_type',
    method: 'get',
    params
  });
};

// 意见反馈提交
export const subOpinion = (data) => {
  return axios({
    baseURL,
    url: '/village/opinion/submit',
    method: 'post',
    data
  });
};

// 我的问题列表
export const getAnswerList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/list',
    method: 'get',
    params
  });
};


// 社情共享列表
export const getSocialSharingList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/socialInformation',
    method: 'get',
    params
  });
};

// 社情共享列表-个人
export const getSocialSharingPersonList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/list',
    method: 'get',
    params
  });
};

// // 我的问题详情
// export const getAnswerDetail = (params) => {
//   return axios({
//     baseURL,
//     url: '/village/agriculture/answer/list',
//     method: 'get',
//     params
//   });
// };

// 回答列表
export const getAnswerDetailList = (params) => {
  return axios({
    baseURL,
    url: '/village/agriculture/answer/list',
    method: 'get',
    params
  });
};

// 乡村综治列表（事件上报 民事调解）
export const getMyEventList = (params) => {
  return axios({
    baseURL,
    // url: '/manage/uploadEvent/list',
    url: '/manage/uploadEvent/public/list',
    method: 'get',
    params
  });
};

// 网络培训列表
export const getVideoList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/list',
    method: 'get',
    params
  });
};

// 网络培训列表
export const dealEvent = (data) => {
  return axios({
    baseURL,
    url: '/manage/uploadEvent/submit',
    method: 'post',
    data
  });
};

// notice列表
export const getNoticeList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/public/publicTenant',
    // url: '/blade-system/notice/publicTenant',
    method: 'get',
    params
  });
};

// notice详情
export const getNoticeDetail = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/publicTenant',
    method: 'get',
    params
  });
};

// 反馈
export const feedBack = (params) => {
  return axios({
    baseURL,
    url: '/manage/uploadEvent/feedback',
    method: 'post',
    params
  });
};
